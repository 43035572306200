import { FC } from 'react';
import { IconButton, Tooltip, useTheme } from '@mui/material';
import { TranslatableString } from 'i18n/translations/no';
import { useTranslation } from 'react-i18next';

type Props = {
  text: TranslatableString;
  icon: JSX.Element;
  onClick: () => void;
};

const IconButtonWithTooltip: FC<Props> = ({ text, icon, onClick }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const iconStyle = {
    '&:hover': {
      background: theme.colors.primary.lighter,
    },
    color: theme.palette.primary.main,
  };

  return (
    <Tooltip title={t(text)} arrow>
      <IconButton sx={iconStyle} size="small" onClick={onClick}>
        {icon}
      </IconButton>
    </Tooltip>
  );
};

export default IconButtonWithTooltip;
