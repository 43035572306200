import { Tabs } from '@base-ui-components/react/tabs';
import { tabClasses } from '@mui/base';
import { styled } from '@mui/system';

const StyledTab = styled(Tabs.Tab)`
  font-family: IBM Plex Sans, sans-serif;
  cursor: pointer;
  color: #808080;
  font-size: 0.875rem;
  font-weight: bold;
  width: 100%;
  padding: 14px 16px;
  margin: 5px 0px;
  display: flex;
  justify-content: center;
  border: none;
  background: none;
  border-bottom: 2px solid #87ceeb;

  &:focus {
    color: #fff;
  }
  &[data-selected] {
    border: 2px solid #87ceeb;
    border-radius: 10px 10px 0px 0px;
    border-bottom: none;
    color: #808080;
  }

  &.${tabClasses.selected} {
    border: 2px solid #87ceeb;
    border-radius: 10px 10px 0px 0px;
    border-bottom: none;
    color: #808080;
  }

  &.${tabClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const StyledTabPanel = styled(Tabs.Panel)`
  width: 100%;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
`;

const TabsList = styled(Tabs.List)`
  min-width: 320px;

  /* border-color: '#92a8d1'; */
  border-radius: 8px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
`;

export { StyledTab as Tab, TabsList, StyledTabPanel as TabPanel };
