import { createApi } from '@reduxjs/toolkit/query/react';
import {
  CameraVehiclesShape,
  MatchingApiResponse,
  MatchingShape,
  SightingsApiResponse,
} from 'models/Matching';
import { baseQuery } from '..';

export const MatchingApi = createApi({
  reducerPath: 'matching',
  tagTypes: ['matching-list', 'matching', 'sightings'],
  baseQuery,
  endpoints: (builder) => ({
    getMatchingList: builder.query<MatchingApiResponse, string>({
      query: (url) => ({
        url: url,
      }),
      providesTags: ['matching-list'],
    }),
    getMatching: builder.query<MatchingShape, string>({
      query: (url) => ({
        url: url,
      }),
      providesTags: ['matching'],
    }),
    getSightings: builder.query<SightingsApiResponse, string>({
      query: (url) => ({
        url: url,
      }),
      providesTags: ['sightings'],
    }),
    getCameraSightings: builder.query<CameraVehiclesShape, string>({
      query: (url) => ({
        url: url,
      }),
      providesTags: ['sightings'],
    }),
  }),
});

export const {
  useGetMatchingListQuery,
  useGetMatchingQuery,
  useGetSightingsQuery,
  useGetCameraSightingsQuery,
} = MatchingApi;
