import { Typography } from '@mui/material';
import CustomDialog from 'components/_shared/Dialog/dialog';
import { SubscriptionPlan } from 'models/Subscriptions';
import { FC } from 'react';
import EditSubscription from '../List/ListActions/EditSubscription';
import AddSubscription from './AddSubscription';

type Props = {
  handleClose: () => void;
  isSubscriptionCreateModal?: boolean;
  isSubscriptionEditModal?: boolean;
  subscription?: SubscriptionPlan | null;
  addSubscriptionLink?: string | null;
};

const SubscriptionModal: FC<Props> = ({
  handleClose,
  isSubscriptionCreateModal,
  isSubscriptionEditModal,
  subscription,
  addSubscriptionLink = null,
}): JSX.Element => {
  const modalTitle = isSubscriptionCreateModal
    ? 'Create new subscription'
    : isSubscriptionEditModal && subscription
    ? `View subscription - ${subscription.Name}`
    : '';

  return (
    <CustomDialog
      header={
        <Typography variant="h3" color="text.secondary">
          {modalTitle}
        </Typography>
      }
      onClose={handleClose}
    >
      {isSubscriptionCreateModal && (
        <AddSubscription
          addSubscriptionLink={addSubscriptionLink}
          handleClose={handleClose}
        />
      )}
      {isSubscriptionEditModal && subscription && (
        <EditSubscription
          subscriptionPlan={subscription}
          handleClose={handleClose}
        />
      )}
    </CustomDialog>
  );
};

export default SubscriptionModal;
