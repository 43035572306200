import React, { CSSProperties, FC, ReactNode, useState } from 'react';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import GeneralTab from './GeneralTab';
import CarParkTab from './CarParkTab';
import WhitelistedUsersTab from './WhitelistedUsersTab';
import { SubscriptionPlan } from 'models/Subscriptions';

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}
type Props = {
  handleClose: () => void;
  subscriptionPlan: SubscriptionPlan;
};
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const tabStyle: CSSProperties = {
  textTransform: 'none',
};

const EditSubscription: FC<Props> = ({ subscriptionPlan, handleClose }) => {
  const [value, setValue] = useState<number>(0);
  const [carParksLink, setCarParksLink] = useState('');
  const [whiteListedUsersLink, setwhiteListedUsersLink] = useState('');

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="General" {...a11yProps(0)} />
          <Tab style={tabStyle} label="Car parks" {...a11yProps(1)} />
          <Tab style={tabStyle} label="Whitelisted users" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <GeneralTab
          handleClose={handleClose}
          subscriptionPlan={subscriptionPlan}
          setCarParksLink={setCarParksLink}
          setwhiteListedUsersLink={setwhiteListedUsersLink}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <CarParkTab carParksLink={carParksLink} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <WhitelistedUsersTab whiteListedUsersLink={whiteListedUsersLink} />
      </TabPanel>
    </Box>
  );
};

export default EditSubscription;
