import { createApi } from '@reduxjs/toolkit/query/react';
import {
  ContractShape,
  EndUserResponseShape,
  EndUserShape,
  SubscriptionResponseShape,
  TransactionResponseShape,
  VehicleResponseShape,
  WhitelistedUserResponseShape,
} from 'models/EndUsers';

import { baseQuery } from '..';

export const UsersApi = createApi({
  reducerPath: 'usersApi',
  tagTypes: ['whitelisted-users'],
  baseQuery,
  endpoints: (builder) => ({
    getTableData: builder.query<EndUserResponseShape, string>({
      query: (url) => ({
        url: url,
      }),
    }),
    getWhitelistedUsers: builder.query<WhitelistedUserResponseShape, string>({
      query: (url) => ({
        url: url,
      }),
      providesTags: ['whitelisted-users'],
    }),
    getUserById: builder.query<EndUserShape, string>({
      query: (url) => ({
        url: url,
      }),
    }),
    contractList: builder.query<ContractShape[], string>({
      query: (url) => ({
        url: url,
      }),
    }),
    subscriptionList: builder.query<SubscriptionResponseShape, string>({
      query: (url) => ({
        url: url,
      }),
    }),
    transactionList: builder.query<TransactionResponseShape, string>({
      query: (url) => ({
        url: url,
      }),
    }),
    vehiclesList: builder.query<VehicleResponseShape, string>({
      query: (url) => ({
        url: url,
      }),
    }),
    deleteUserByID: builder.mutation<EndUserShape, string>({
      query: (url) => ({
        url: url,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useGetTableDataQuery,
  useGetWhitelistedUsersQuery,
  useGetUserByIdQuery,
  useContractListQuery,
  useSubscriptionListQuery,
  useTransactionListQuery,
  useVehiclesListQuery,
  useDeleteUserByIDMutation,
} = UsersApi;
