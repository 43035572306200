import {
  Box,
  Grid,
  TextField,
  Button,
  CircularProgress,
  Snackbar,
  Alert,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import Api from 'Api';
import { getApiHrefByRel } from 'Api/ApiCommonFunctions';
import CustomDialog from 'components/_shared/Dialog/dialog';
import { Formik } from 'formik';
import { WhitelistedUser } from 'models/EndUsers';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { dispatch } from 'store';
import { UsersApi } from 'store/_Api/Users';
import * as Yup from 'yup';

interface FormData {
  Email: string;
  MaxSubscriptions: number | null;
}

type ApiErrorResponse = {
  Message: string;
  Errors: {
    [key: string]: string[];
  };
};

type Props = {
  addLink?: string;
  data?: WhitelistedUser;
  onClose: () => void;
};
const WhitelistUserModal: FC<Props> = ({ data, addLink, onClose }) => {
  const modalTitle = !data
    ? 'Add user to whitelist'
    : 'Update user in the whitelist';

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { t } = useTranslation();

  const initialValues: FormData = {
    Email: data?.Email ?? '',
    MaxSubscriptions: data?.MaxSubscriptions ?? null,
  };

  const updateLink = getApiHrefByRel(data?.Links ?? [], 'update');

  const validationSchema = Yup.object().shape({
    Email: Yup.string().required('Email is required'),
  });

  const handleApiCall = async (link: string, payload: object) => {
    try {
      const addedResponse = await Api.post(link, payload);
      if (addedResponse.status < 400) {
        dispatch(UsersApi.util.invalidateTags(['whitelisted-users']));

        onClose();
      }
    } catch (error) {
      const formatErrorMessages = (errorResponse: ApiErrorResponse): string => {
        let formattedMessage = '';
        for (const [, messages] of Object.entries(errorResponse.Errors)) {
          formattedMessage += `${messages.join(', ')}\n`;
        }
        return formattedMessage.trim();
      };
      const apiError = error as ApiErrorResponse;
      setErrorMessage(formatErrorMessages(apiError));
    }
  };

  const handleSubmit = async (values: FormData): Promise<void> => {
    const { Email, MaxSubscriptions } = values;
    if (updateLink && data) {
      const { UserGuid } = data;
      handleApiCall(updateLink, {
        Email,
        MaxSubscriptions,
        UserGuid,
      });

      return;
    }

    if (!addLink) {
      console.error('Could not submit form because there wasnt an add link');
      return;
    }
    handleApiCall(addLink, {
      Email,
      MaxSubscriptions,
    });
  };
  return (
    <CustomDialog
      header={
        <Typography variant="h3" color="text.secondary">
          {modalTitle}
        </Typography>
      }
      title={modalTitle}
      onClose={onClose}
      width="sm"
    >
      <Box px={3}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            errors,
            handleBlur,
            handleSubmit,
            handleChange,
            isSubmitting,
            touched,
            values,
          }): JSX.Element => {
            return (
              <form onSubmit={handleSubmit}>
                <Grid container alignContent="center" flexDirection="column">
                  <Grid width="60%" item xs={8}>
                    <TextField
                      label={t('Email')}
                      error={Boolean(touched.Email && errors.Email)}
                      margin="normal"
                      helperText={touched.Email && errors.Email}
                      fullWidth
                      name="Email"
                      sx={{ my: 1 }}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                      value={values.Email}
                      variant="outlined"
                      disabled={!data ? false : true}
                    />
                  </Grid>

                  <Grid
                    sx={{
                      display: 'flex',
                      justifyContent: 'start',
                      gap: 2,
                    }}
                  >
                    <TextField
                      error={Boolean(
                        touched.MaxSubscriptions && errors.MaxSubscriptions,
                      )}
                      margin="normal"
                      helperText={
                        touched.MaxSubscriptions && errors.MaxSubscriptions
                      }
                      label={t('Max subscriptions')}
                      name="MaxSubscriptions"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="number"
                      value={values.MaxSubscriptions}
                      sx={{ my: 1 }}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
                <Grid sx={{ display: 'flex', justifyContent: 'end' }}>
                  <Button
                    color="primary"
                    startIcon={
                      isSubmitting ? <CircularProgress size="1rem" /> : null
                    }
                    disabled={isSubmitting}
                    type="submit"
                    size="large"
                    variant="contained"
                  >
                    {updateLink ? t('Save') : t('Add')}
                  </Button>
                </Grid>

                <Snackbar
                  open={Boolean(errorMessage)}
                  autoHideDuration={6000}
                  anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
                  onClose={() => setErrorMessage(null)}
                >
                  <Alert onClose={() => setErrorMessage(null)} severity="error">
                    {errorMessage}
                  </Alert>
                </Snackbar>
              </form>
            );
          }}
        </Formik>
      </Box>
    </CustomDialog>
  );
};
export default WhitelistUserModal;
