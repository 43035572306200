/**
 * FormatDate will format input date and return in date format of 1-1-2022.
 */

import { t } from 'i18next';
import moment from 'moment-timezone';

export const formatDate = (date: string): string => {
  const selectedDate = new Date(date);
  const mm = selectedDate.getMonth() + 1;
  const dd = selectedDate.getDate();
  const yyyy = selectedDate.getFullYear();

  return `${dd}-${mm}-${yyyy}`;
};

export const formatTimeDate = (dateTime: Date): string => {
  const modifiedDateTime = dateTime
    .toString()
    .replace('T', ' ')
    .replace('Z', ' ')
    .replaceAll('-', '.')
    .slice(0, -4);

  return modifiedDateTime;
};

// * Formats a date string into a more readable format.
export const prettifyDate = (dateStr: string): string => {
  const date = new Date(dateStr);
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  };
  return date.toLocaleString('en-US', options);
};
/**
 * Calculates time difference in user friendly format. for example: 10h 12m
 */
export function getTimeDifference(startTime: string, endTime: string): string {
  try {
    /**
     * Time difference in miliseconds
     */
    const msDiff = new Date(endTime).getTime() - new Date(startTime).getTime();
    /**
     * Time difference in minutes
     */
    const mDiff = Math.floor(msDiff / 1000 / 60);

    const hours = Math.floor(mDiff / 60);
    const minutes = mDiff - hours * 60;

    if (!!hours && !!minutes) {
      return `${hours}h ${minutes}m`;
    }

    if (!!hours && !minutes) {
      return `${hours}h`;
    }

    return `${minutes}m`;
  } catch (e) {
    console.error('Error when calculating time diff:', e);
    return 'n/a';
  }
}
export const getEnteredAtDate = (
  data: string | undefined,
  timezone: string,
): Date | undefined => {
  if (!data) return;
  const utcEntryTime = new Date(data).toISOString();

  const offset = new Date(utcEntryTime).getTimezoneOffset();

  const m = moment(utcEntryTime).tz(timezone);
  m.add(m.utcOffset() + offset, 'minutes');
  const newEntryTime = new Date(m.utc().format());
  return newEntryTime;
};

export const getExitedAtDate = (
  data: string | undefined,
  timezone: string,
): Date | undefined => {
  if (!data) return;
  const utcExitTime = new Date(data).toISOString();

  const offset = new Date(utcExitTime).getTimezoneOffset();

  const m = moment(utcExitTime).tz(timezone);
  m.add(m.utcOffset() + offset, 'minutes');
  const newExitTime = new Date(m.utc().format());
  return newExitTime;
};

export const getFormattedDateTime = (
  dateTime: string | undefined,
  timezone: string,
): string => {
  if (!dateTime) {
    return 'n/a';
  }
  const timeZonned = timezone
    ? moment(dateTime).tz(timezone)
    : moment(dateTime);

  return (
    timeZonned.format('DD.MM.YYYY ') + t('at') + timeZonned.format(' HH:mm')
  );
};

/**
 * Converts a UTC datetime string to a JavaScript `Date` object in a specific timezone.
 *
 * - This function **takes a UTC timestamp** (e.g., `"2025-03-14T22:00:00.000Z"`)
 *   and **shifts it to the given timezone** (e.g., `"Europe/Oslo"`).
 * - The resulting `Date` object represents **the correct local time in that timezone**.
 *
 * 🔍 **Example:**
 *   - **Input:** `"2025-03-10T23:00:00Z"`, Timezone: `"Europe/Oslo"`
 *   - **Output:** `Date Tue Mar 11 2025 00:00:00 GMT+0100 (Central European Standard Time)`
 *   - **Explanation:** `"23:00 UTC"` → `"00:00"` in `"Europe/Oslo"` (UTC+1 at that time).
 *
 * 📌 **Key Notes:**
 * - The `Date` object is stored in the system’s local timezone when logged in the console.
 * - However, the **actual time value inside `Date` remains correct** for `"Europe/Oslo"`.
 * - When formatted correctly (`.toISOString()`), it will still provide accurate data.
 */
export const utcToTimeZoneDate = (
  dateTime: string | null | undefined,
  timezone: string,
): Date | null => {
  if (!dateTime) return null;

  /**
   * ✅ Convert from UTC to the given timezone.
   * - Ensures `dateTime` is interpreted as **UTC** and shifted to `timezone`.
   * - The returned Moment object represents the correct local time.
   */
  const localMoment = moment.utc(dateTime).tz(timezone);

  /**
   * ✅ Convert to JavaScript `Date` (but beware of how it logs).
   * - The `Date` object will reflect the correct local time in `timezone`.
   * - However, when logged in the browser console, it **may appear in the system’s local timezone**.
   * - **Important:** The actual stored time remains correct for `timezone`.
   */
  const asDate = new Date(
    localMoment.year(),
    localMoment.month(),
    localMoment.date(),
    localMoment.hour(),
    localMoment.minute(),
    localMoment.second(),
  ); // ✅ Ensures the JavaScript `Date` object exactly matches the intended time

  return asDate; // ✅ Returns a JavaScript `Date` object with the expected time in `timezone`
};

/**
 * Converts a local JavaScript `Date` (assumed to be in a specific timezone) to a UTC ISO string.
 *
 * - The input `dateTime` is a **local `Date` object** in the given `timezone` (e.g., `"Europe/Oslo"`).
 * - This function:
 *   1️⃣ **Strips any implicit timezone offsets from the `Date` object.**
 *   2️⃣ **Re-interprets the stripped date as if it were set in the given `timezone`.**
 *   3️⃣ **Converts the correctly interpreted time to UTC and returns an ISO string (`YYYY-MM-DDTHH:mm:ss.sssZ`).**
 *
 * 🔍 **Example:**
 *   - **Input:** `Date Tue Mar 11 2025 00:00:00 GMT+0200` (in `"Europe/Oslo"`)
 *   - **Output (UTC):** `"2025-03-10T23:00:00.000Z"`
 *   - **Explanation:** `"00:00"` in Oslo → `"23:00"` UTC.
 *
 * 📌 **Key Notes:**
 * - JavaScript `Date` objects always store time in the **system's local timezone**.
 * - This function ensures that **the exact date and time are correctly converted to UTC** for backend storage.
 * - The final UTC timestamp can safely be stored in a database without timezone inconsistencies.
 */
export const toUTCFromTimezone = (
  dateTime: Date | null | undefined,
  timezone: string, // This should be "Europe/Oslo"
): string | null => {
  if (!dateTime) return null;

  /**
   * ✅ Step 1: Strip timezone information by extracting only the Y/M/D/H/M.
   * - The JavaScript `Date` object inherently includes timezone information.
   * - We create a "naive" moment object to **remove implicit timezone shifts**.
   */
  const naiveDate = moment({
    year: dateTime.getFullYear(),
    month: dateTime.getMonth(),
    day: dateTime.getDate(),
    hour: dateTime.getHours(),
    minute: dateTime.getMinutes(),
  });

  /**
   * ✅ Step 2: Tell Moment.js to interpret the naive date as belonging to the given `timezone`.
   * - This ensures that `00:00` in Oslo remains **exactly** `00:00` before conversion.
   * - Without this step, Moment might misinterpret the naive date as UTC.
   */
  const targetTime = moment.tz(
    naiveDate.format('YYYY-MM-DD HH:mm'),
    'YYYY-MM-DD HH:mm',
    timezone,
  );

  /**
   * ✅ Step 3: Convert to UTC for backend storage.
   * - `.utc()` shifts the time to Coordinated Universal Time (UTC).
   * - `.toISOString()` returns the correct ISO format (`YYYY-MM-DDTHH:mm:ss.sssZ`).
   */
  return targetTime.utc().toISOString();
};
