import {
  Alert,
  Button,
  CircularProgress,
  Grid,
  Typography,
  useTheme,
} from '@mui/material';
import Api from 'Api';
import { PlusIcon } from 'atoms/Icons';
import CustomDialog from 'components/_shared/Dialog/dialog';
import { CarPark, CarParksApiResponse } from 'models/CarParks';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { dispatch } from 'store';
import { useBaseLinks } from 'store/Auth';
import { FacilitiesApi } from 'store/_Api/Facilities';
import { parseTemplate } from 'url-template';

type Props = {
  handleClose: () => void;
  addCarParksLink: string;
};
interface ErrorResponse {
  Message: string;
  Errors: Record<string, string[]>;
}

const AddCarParks: FC<Props> = ({ handleClose, addCarParksLink }) => {
  const { getLink } = useBaseLinks();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const [carParksData, setcarParksData] = useState<CarPark[] | []>([]);
  const carParks = getLink('search-car-parks') ?? '';
  const [errorResponse, setErrorResponse] = useState<ErrorResponse | null>(
    null,
  );

  const theme = useTheme();
  const formatErrorMessages = (errorResponse: ErrorResponse): string => {
    let formattedMessage = '';
    for (const [, messages] of Object.entries(errorResponse.Errors)) {
      formattedMessage += `${messages.join(', ')}\n`;
    }
    return formattedMessage.trim();
  };
  const handleAddToSubscription = async (CarParkGuid: string) => {
    if (addCarParksLink) {
      try {
        setIsLoading(true);
        const response = await Api.post(addCarParksLink, { CarParkGuid });
        if (response.status < 400) {
          setIsLoading(false);
          handleClose();
          dispatch(FacilitiesApi.util.invalidateTags(['car-parks']));
        }
      } catch (error) {
        setIsLoading(false);
        setErrorResponse(error as ErrorResponse);
      }
    } else {
      console.error('No add car park link );');
    }
  };
  useEffect(() => {
    const carParksLinkParsed = parseTemplate(decodeURI(carParks)).expand({
      pagesize: 50,
      page: 0,
      q: '',
    });
    if (carParksLinkParsed) {
      setIsLoading(true);
      Api.get(carParksLinkParsed)
        .then((res) => {
          const data = res.data as CarParksApiResponse;
          setcarParksData(data.Items);
          setIsLoading(false);
        })
        .catch((err) => {
          console.error(err);
          setIsLoading(false);
        });
    }
  }, [carParks]);

  return (
    <CustomDialog
      width={'xs'}
      header={
        <Grid>
          <Typography variant="h2" color="text.secondary">
            {t('Add car park areas')}
          </Typography>
        </Grid>
      }
      onClose={handleClose}
      title={t('Add car park areas')}
    >
      {errorResponse && (
        <Alert severity="error" sx={{ width: '100%', padding: '5px' }}>
          {formatErrorMessages(errorResponse)}
        </Alert>
      )}
      {isLoading && (
        <Grid display={'flex'} justifyContent={'center'}>
          <CircularProgress />
        </Grid>
      )}
      {carParksData.map((carPark) => {
        return (
          <Grid
            container
            display={'flex'}
            sx={{ padding: '10px' }}
            key={carPark.CarParkGuid}
            justifyContent={'space-between'}
          >
            <Typography variant="h3" color="text.secondary">
              {carPark.Name}
            </Typography>
            <Button
              variant="contained"
              style={{ lineHeight: '0.75' }}
              startIcon={
                <PlusIcon
                  fill={theme.colors.alpha.white[100]}
                  opacity={1}
                  height={15}
                />
              }
              onClick={() => handleAddToSubscription(carPark.CarParkGuid)}
            >
              {t('Add to subscription')}
            </Button>
          </Grid>
        );
      })}
    </CustomDialog>
  );
};

export default AddCarParks;
