import React, { FC } from 'react';
import {
  styled,
  Dialog,
  DialogTitle,
  DialogContent,
  useTheme,
  Breakpoint,
  DialogActions,
  Button,
  Divider,
} from '@mui/material';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';

import { TransitionProps } from '@mui/material/transitions';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogWrapper = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    backgroundColor: theme.palette.background.default,
    border: 'none',
  },
}));

interface DialogProps {
  onClose: () => void;
  children: React.ReactNode;
}

const BootstrapDialogTitle: FC<DialogProps> = (props) => {
  const { children, onClose, ...other } = props;
  const theme = useTheme();
  const location = useLocation();

  return (
    <DialogTitle
      sx={{
        m: 0,
        fontSize: '20px',
        fontWeight: '600',
        backgroundColor: theme.palette.background.default,
        borderBottom: location.pathname.includes('parkings')
          ? '2px solid lightgrey'
          : '',
      }}
      {...other}
    >
      {children}

      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
    </DialogTitle>
  );
};

interface Props {
  onClose: () => void;
  children: React.ReactNode;
  title?: string | '';
  icon?: JSX.Element;
  header?: React.ReactNode;
  width?: Breakpoint | undefined;
  handleAdd?: () => void;
  actions?: boolean;
  actionButtonName?: 'Add' | 'Delete' | 'Save';
  isLoading?: boolean;
}

const CustomDialog: FC<Props> = ({
  onClose,
  handleAdd,
  children,
  title,
  header,
  width,
  actions = false,
  actionButtonName,
  isLoading,
}): JSX.Element => {
  const theme = useTheme();
  const [open] = React.useState(true);
  const { t } = useTranslation();

  return (
    <div>
      <DialogWrapper
        disableEnforceFocus={true}
        onClose={onClose}
        maxWidth={width ? width : 'lg'}
        fullWidth={true}
        open={open}
        sx={{
          p: 0,
          background: theme.colors.blackAlt.main,
          position: 'fixed',
        }}
        TransitionComponent={Transition}
      >
        <BootstrapDialogTitle onClose={onClose}>
          {!!header && header}
          {!!title && title}
        </BootstrapDialogTitle>
        <DialogContent dividers style={{ overflowY: 'hidden' }}>
          {children}
        </DialogContent>
        {actions && (
          <>
            <Divider />
            <DialogActions
              sx={{ backgroundColor: theme.palette.background.default, pr: 2 }}
            >
              <Button variant="outlined" autoFocus onClick={onClose}>
                {t('Cancel')}
              </Button>
              <Button
                variant="contained"
                autoFocus
                onClick={handleAdd}
                disabled={isLoading}
              >
                {t(actionButtonName ? actionButtonName : 'Add')}
              </Button>
            </DialogActions>
          </>
        )}
      </DialogWrapper>
    </div>
  );
};

export default CustomDialog;
