import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery } from '..';
import { VehicleGroupsApiResponse } from 'models/VehicleGroup';
import {
  VehicleGroupBaseData,
  VehicleGroupFromApi,
} from 'models/VehicleGroup/VehicleGroupModel';

export type PostType = {
  url: string;
  payload: VehicleGroupBaseData | VehicleGroupFromApi;
};

export const VehicleGroupsApi = createApi({
  reducerPath: 'vehicleGroupList',
  tagTypes: ['VehicleGroups', 'SavedVehicleGroups'],
  baseQuery,
  endpoints: (builder) => ({
    getVehicleGroups: builder.query<VehicleGroupsApiResponse, string>({
      query: (url) => ({
        url: url,
      }),
      providesTags: ['VehicleGroups'],
    }),
    saveVehicleGroup: builder.mutation<VehicleGroupFromApi, PostType>({
      query: ({ url, payload }) => ({
        url: url,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['VehicleGroups'],
    }),
    removeVehicleGroup: builder.mutation<void, string>({
      query: (url) => ({
        url: url,
        method: 'DELETE',
      }),
      invalidatesTags: ['VehicleGroups'],
    }),
  }),
});

export const {
  useGetVehicleGroupsQuery,
  useSaveVehicleGroupMutation,
  useRemoveVehicleGroupMutation,
} = VehicleGroupsApi;
