import CustomDialog from 'components/_shared/Dialog/dialog';
import { EndUserShape } from 'models/EndUsers';
import { FC, useEffect, useState } from 'react';

import {
  Tab,
  TabsList,
  TabPanel,
} from 'components/_shared/Tabs/Tab/_css/TabCssConfig';
import { Box } from '@mui/material';
import { Tabs } from '@base-ui-components/react/tabs';

import { default as EventsTab } from './Tab/Events';
import { default as TransactionsTab } from './Tab/Transactions';
import { default as VehiclesTab } from './Tab/Vehicles';
import { default as ContractsTab } from './Tab/Contracts';
import { default as SubscriptionsTab } from './Tab/Subscriptions';
import { default as InfoTab } from './Tab/Info';

type Props = {
  handleClose: () => void;
  user: EndUserShape;
  handleDelete: (user: EndUserShape) => void;
};

type TabId =
  | 'information'
  | 'contracts'
  | 'transactions'
  | 'vehicles'
  | 'subscriptions'
  | 'events';

interface TabShape {
  index: number;
  id: TabId;
  component: JSX.Element;
}

/**
 * Gets all tabs for the modal
 */
const getTabs = (
  user: EndUserShape,
  handleDelete: (user: EndUserShape) => void,
): TabShape[] => {
  const linksRels = user.Links.map((link) => link.Rel);
  /**
   * Gets tab requested by id, if that id is allowed by linksRels,
   * or if allowAny is set to true
   */
  const getTab = (
    index: number,
    id: TabId,
    allowAny = false,
  ): TabShape | undefined => {
    if (!allowAny && !linksRels.includes(id)) {
      /**
       * If allow any is not true,
       * or if linksRels does not include the given id,
       * then we return undefined and stop further execution
       */
      return undefined;
    }

    const baseData = {
      index: index,
      id: id,
    };
    /**
     * Gets tab component for the given id
     */
    const getComponent = () => {
      const props = {
        user: user,
        handleDelete: handleDelete,
      };
      switch (id) {
        case 'information':
          return <InfoTab {...props} />;

        case 'contracts':
          return <ContractsTab {...props} />;

        case 'transactions':
          return <TransactionsTab {...props} />;

        case 'vehicles':
          return <VehiclesTab {...props} />;

        case 'subscriptions':
          return <SubscriptionsTab {...props} />;

        case 'events':
          return <EventsTab {...props} />;

        default:
          return undefined;
      }
    };

    const component = getComponent();
    if (component) {
      return {
        ...baseData,
        component: component,
      };
    }

    return undefined;
  };

  const infoTab = getTab(1, 'information', true) as TabShape;
  const tabs: TabShape[] = [infoTab];

  const contractsTab = getTab(tabs.length + 1, 'contracts');
  if (contractsTab) {
    tabs.push(contractsTab);
  }

  const transactionsTab = getTab(tabs.length + 1, 'transactions');
  if (transactionsTab) {
    tabs.push(transactionsTab);
  }
  const vehiclesTab = getTab(tabs.length + 1, 'vehicles');
  if (vehiclesTab) {
    tabs.push(vehiclesTab);
  }

  const subscriptionsTab = getTab(tabs.length + 1, 'subscriptions');
  if (subscriptionsTab) {
    tabs.push(subscriptionsTab);
  }

  const eventsTab = getTab(tabs.length + 1, 'events', true) as TabShape;
  tabs.push(eventsTab);

  return tabs;
};

const getTabProps = (index: number) => {
  return {
    value: index,
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
};

const titlelify = (value: string): string => {
  return value.charAt(0).toUpperCase() + value.slice(1);
};
const EndUserModal: FC<Props> = ({
  handleClose,
  user,
  handleDelete,
}): JSX.Element => {
  const [tabs, setTabs] = useState<TabShape[]>([]);
  useEffect(() => {
    setTabs(getTabs(user, handleDelete));
  }, [handleDelete, user]);

  return (
    <CustomDialog onClose={handleClose} header={user.Name}>
      <Box>
        <Tabs.Root defaultValue={1}>
          <TabsList>
            {tabs.map((tab) => (
              <Tab key={tab.index} {...getTabProps(tab.index)}>
                {titlelify(tab.id)}
              </Tab>
            ))}
          </TabsList>

          {tabs.map((tab, index) => (
            <TabPanel value={tab.index} key={index}>
              {tab.component}
            </TabPanel>
          ))}
        </Tabs.Root>
      </Box>
    </CustomDialog>
  );
};

export default EndUserModal;
