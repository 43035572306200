import {
  Button,
  CircularProgress,
  Grid,
  TextField,
  Snackbar,
  Alert,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import { Box } from '@mui/system';
import Api from 'Api';
import { dispatch } from 'store';
import DateTimeRangePicker from 'atoms/components/DateTimeRangePicker';

import { Formik } from 'formik';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { SubscriptionsApi } from 'store/_Api/Subscriptions';
import { InformationIcon } from 'atoms/Icons';
import IconButtonWithTooltip from 'components/_shared/IconButton';

interface FormData {
  Name: string;
  Price: number;
  VatRate: number;
  MaxConcurrentUses: string;
  PeriodDays: number;
  SalesPeriodFrom: string;
  SalesPeriodTo: string;
  Description: string;
  MaxSubscribers: number | null;
  MaxSubscriptionsPerUser: number | null;
  GrantsFacilityAccess: boolean;
  SubscriptionInvoice: boolean;
}

type Props = {
  addSubscriptionLink: string | null;
  handleClose: () => void;
};
type ApiErrorResponse = {
  Message: string;
  Errors: {
    [key: string]: string[];
  };
};

const AddSubscription: FC<Props> = ({
  addSubscriptionLink,
  handleClose,
}): JSX.Element => {
  const [salesPeriod, setSalesPeriod] = useState<Date | Date[] | string>('');
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { t } = useTranslation();

  const initialValues: FormData = {
    Name: '',
    Price: 0,
    VatRate: 0,
    PeriodDays: 0,
    SalesPeriodFrom: '',
    SalesPeriodTo: '',
    MaxConcurrentUses: '',
    MaxSubscribers: null,
    MaxSubscriptionsPerUser: null,
    Description: '',
    GrantsFacilityAccess: false,
    SubscriptionInvoice: false,
  };

  const validationSchema = Yup.object().shape({
    Name: Yup.string().required('Name is required'),
    Price: Yup.number()
      .min(0, 'Price must be a non-negative number')
      .required('Price is required'),
    PeriodDays: Yup.number()
      .min(1, 'Period Days must be at least 1')
      .required('Period Days is required'),
    VatRate: Yup.number().min(0, 'VAT Rate must be a non-negative number'),
    MaxConcurrentUses: Yup.number().min(
      0,
      'Max Concurrent Uses must be a non-negative number',
    ),
  });
  useEffect(() => {
    console.error(errorMessage);
  }, [errorMessage]);

  return (
    <Box px={3}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values): Promise<void> => {
          if (addSubscriptionLink) {
            let SalesPeriodFrom: Date | string = '';
            let SalesPeriodTo: Date | string = '';
            if (Array.isArray(salesPeriod)) {
              const selectedDate = new Date(salesPeriod[0]);

              const currentDate = new Date();
              currentDate.setMinutes(currentDate.getMinutes() + 5);
              selectedDate.setHours(
                currentDate.getHours(),
                currentDate.getMinutes(),
                currentDate.getSeconds(),
                currentDate.getMilliseconds(),
              );

              SalesPeriodFrom = selectedDate.toISOString();
              SalesPeriodTo = salesPeriod[1];
            }
            const {
              Name,
              Description,
              PeriodDays,
              Price,
              VatRate,
              MaxConcurrentUses,
              MaxSubscribers,
              MaxSubscriptionsPerUser,
              GrantsFacilityAccess,
              SubscriptionInvoice,
            } = values;

            const payload = {
              Name,
              Description,
              PeriodDays,
              Price,
              VatRate,
              SalesPeriodFrom,
              SalesPeriodTo,
              MaxConcurrentUses,
              MaxSubscribers,
              MaxSubscriptionsPerUser,
              GrantsFacilityAccess,
              SubscriptionInvoice,
            };

            try {
              const createdSubscription = await Api.post(
                addSubscriptionLink,
                payload,
              );
              if (createdSubscription.status === 201) {
                dispatch(
                  SubscriptionsApi.util.invalidateTags(['subscriptions']),
                );

                handleClose();
              }
            } catch (error) {
              const formatErrorMessages = (
                errorResponse: ApiErrorResponse,
              ): string => {
                let formattedMessage = '';
                for (const [, messages] of Object.entries(
                  errorResponse.Errors,
                )) {
                  formattedMessage += `${messages.join(', ')}\n`;
                }
                return formattedMessage.trim();
              };
              const apiError = error as ApiErrorResponse;
              setErrorMessage(formatErrorMessages(apiError));
            }
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleSubmit,
          handleChange,
          isSubmitting,
          touched,
          values,
        }): JSX.Element => {
          return (
            <form onSubmit={handleSubmit}>
              <Grid container alignContent="center" flexDirection="column">
                <Grid width="60%" item xs={8}>
                  <TextField
                    label={t('Name')}
                    error={Boolean(touched.Name && errors.Name)}
                    margin="normal"
                    helperText={touched.Name && errors.Name}
                    fullWidth
                    name="Name"
                    sx={{ my: 1 }}
                    placeholder="Write subscription name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.Name}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <DateTimeRangePicker
                    defaultDateRange={salesPeriod}
                    handleChange={setSalesPeriod}
                    placeholder={t('Sales period')}
                    style={{ marginRight: '16px' }}
                    disablePastDates={true}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label={t('Description')}
                    error={Boolean(touched.Description && errors.Description)}
                    margin="normal"
                    helperText={touched.Description && errors.Description}
                    fullWidth
                    sx={{ my: 1 }}
                    name="Description"
                    placeholder="Write subscription description"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.Description}
                    variant="outlined"
                    multiline
                    rows={3}
                  />
                </Grid>

                <Grid
                  sx={{
                    display: 'flex',
                    justifyContent: 'start',
                    gap: 2,
                  }}
                >
                  <TextField
                    error={Boolean(touched.Price && errors.Price)}
                    margin="normal"
                    helperText={touched.Price && errors.Price}
                    label={t('Price')}
                    name="Price"
                    sx={{ my: 1 }}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="number"
                    value={values.Price}
                    variant="outlined"
                  />

                  <TextField
                    label={'VAT (%tax)'}
                    sx={{ my: 1 }}
                    error={Boolean(touched.VatRate && errors.VatRate)}
                    margin="normal"
                    helperText={touched.VatRate && errors.VatRate}
                    name="VatRate"
                    placeholder="%"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="number"
                    value={values.VatRate}
                    variant="outlined"
                  />
                </Grid>
                <Grid>
                  <TextField
                    error={Boolean(touched.PeriodDays && errors.PeriodDays)}
                    margin="normal"
                    sx={{ my: 1 }}
                    helperText={touched.PeriodDays && errors.PeriodDays}
                    label={t('Period days')}
                    name="PeriodDays"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="number"
                    value={values.PeriodDays}
                    variant="outlined"
                  />
                </Grid>

                <Grid
                  sx={{
                    display: 'flex',
                    justifyContent: 'start',
                    gap: 2,
                  }}
                >
                  <TextField
                    error={Boolean(
                      touched.MaxConcurrentUses && errors.MaxConcurrentUses,
                    )}
                    margin="normal"
                    helperText={
                      touched.MaxConcurrentUses && errors.MaxConcurrentUses
                    }
                    label={t('Max concurrent uses')}
                    name="MaxConcurrentUses"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="number"
                    value={values.MaxConcurrentUses}
                    sx={{ my: 1 }}
                    variant="outlined"
                  />

                  <FormControlLabel
                    control={
                      <>
                        <Checkbox
                          checked={values.SubscriptionInvoice}
                          onChange={handleChange}
                          name="SubscriptionInvoice"
                        />
                      </>
                    }
                    label={<strong>{t('Subscription invoice')}</strong>}
                  />
                </Grid>

                <Grid
                  sx={{
                    display: 'flex',
                    justifyContent: 'start',
                    gap: 2,
                  }}
                >
                  <TextField
                    error={Boolean(
                      touched.MaxSubscribers && errors.MaxSubscribers,
                    )}
                    margin="normal"
                    sx={{ my: 1 }}
                    helperText={touched.MaxSubscribers && errors.MaxSubscribers}
                    label={t('Max subscribers')}
                    name="MaxSubscribers"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="number"
                    value={values.MaxSubscribers}
                    variant="outlined"
                  />
                  <TextField
                    error={Boolean(
                      touched.MaxSubscriptionsPerUser &&
                        errors.MaxSubscriptionsPerUser,
                    )}
                    margin="normal"
                    helperText={
                      touched.MaxSubscriptionsPerUser &&
                      errors.MaxSubscriptionsPerUser
                    }
                    label={t('Max subscriptions per user')}
                    name="MaxSubscriptionsPerUser"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="number"
                    value={values.MaxSubscriptionsPerUser}
                    sx={{ my: 1 }}
                    variant="outlined"
                  />
                </Grid>

                <FormControlLabel
                  control={
                    <>
                      <Checkbox
                        checked={values.GrantsFacilityAccess}
                        onChange={handleChange}
                        name="GrantsFacilityAccess"
                      />
                    </>
                  }
                  label={
                    <>
                      <strong>Grants facility access </strong>
                      <IconButtonWithTooltip
                        text="Subscribers with an active subscription are granted access to all car parks this subscription is enabled for"
                        icon={<InformationIcon />}
                        onClick={() => {
                          return;
                        }}
                      />
                    </>
                  }
                />
              </Grid>

              <Grid sx={{ display: 'flex', justifyContent: 'end' }}>
                <Button
                  color="primary"
                  startIcon={
                    isSubmitting ? <CircularProgress size="1rem" /> : null
                  }
                  disabled={isSubmitting}
                  type="submit"
                  size="large"
                  variant="contained"
                >
                  {t('Create subscription')}
                </Button>
              </Grid>

              <Snackbar
                open={Boolean(errorMessage)}
                autoHideDuration={6000}
                anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
                onClose={() => setErrorMessage(null)}
              >
                <Alert onClose={() => setErrorMessage(null)} severity="error">
                  {errorMessage}
                </Alert>
              </Snackbar>
            </form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default AddSubscription;
